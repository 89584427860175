<template>
    <div>
        Thông tin tài khoản
    </div>
</template>
<script>
export default {
    name:"AccountInfo",
    components:{},
    emits:[],
    props:[],
    created() {
        
    },
    methods: {
        
    },
    data() {
        return {
            
        }
    },
}
</script>
<style scoped>

</style>